var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [
                        _c("img", {
                          staticClass: "mx-auto",
                          attrs: {
                            src: require("@/assets/images/pages/login.png"),
                            alt: "login",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                      },
                      [
                        _c("div", { staticClass: "p-8 login-tabs-container" }, [
                          _c("div", { staticClass: "vx-card__title mb-4" }, [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v("Login"),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Welcome back, please login to your account."
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.loginWithPassword.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|email",
                                        expression: "'required|email'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      autofocus: "autofocus",
                                      name: "email",
                                      "icon-no-border": "",
                                      icon: "icon icon-user",
                                      "icon-pack": "feather",
                                      "label-placeholder": "Email",
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                                  ),
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mt-6",
                                    attrs: {
                                      type: "password",
                                      name: "password",
                                      "icon-no-border": "",
                                      icon: "icon icon-lock",
                                      "icon-pack": "feather",
                                      "label-placeholder": "Password",
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("password"))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-wrap justify-end my-5",
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        { attrs: { to: "/forgottenpassword" } },
                                        [_vm._v("Forgot Password?")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: { type: "border" },
                                      on: { click: _vm.registerExternal },
                                    },
                                    [_vm._v("Register")]
                                  ),
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "float-right",
                                      on: { click: _vm.loginWithPassword },
                                    },
                                    [_vm._v("Login")]
                                  ),
                                  _c("vs-input", {
                                    staticClass: "hidden",
                                    attrs: { type: "submit", value: "Submit" },
                                  }),
                                ],
                                1
                              ),
                              _c("vs-divider", [_vm._v("OR")]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "social-login-buttons flex flex-wrap items-center mt-4",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "none" } },
                                    [
                                      _c("v-facebook-login", {
                                        attrs: {
                                          "app-id": _vm.fbSdk.facebookAppId,
                                        },
                                        on: {
                                          login: _vm.handleFbLogin,
                                          "sdk-init": _vm.handleFbSdkInit,
                                        },
                                        model: {
                                          value: _vm.fbSdk.model,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fbSdk, "model", $$v)
                                          },
                                          expression: "fbSdk.model",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-facebook pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4",
                                      on: { click: _vm.loginWithFacebook },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "text-white h-4 w-4 svg-inline--fa fa-facebook-f fa-w-9",
                                          attrs: {
                                            "aria-hidden": "true",
                                            focusable: "false",
                                            "data-prefix": "fab",
                                            "data-icon": "facebook-f",
                                            role: "img",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 264 512",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              fill: "currentColor",
                                              d: "M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-google pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4",
                                      on: { click: _vm.loginWithGoogle },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "text-white h-4 w-4 svg-inline--fa fa-google fa-w-16",
                                          attrs: {
                                            "aria-hidden": "true",
                                            focusable: "false",
                                            "data-prefix": "fab",
                                            "data-icon": "google",
                                            role: "img",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 488 512",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              fill: "currentColor",
                                              d: "M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-twitter pt-3 pb-2 px-4 rounded-lg cursor-pointer mr-4",
                                      on: { click: _vm.loginWithTwitter },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "text-white h-4 w-4 svg-inline--fa fa-twitter fa-w-16",
                                          attrs: {
                                            "aria-hidden": "true",
                                            focusable: "false",
                                            "data-prefix": "fab",
                                            "data-icon": "twitter",
                                            role: "img",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 512 512",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              fill: "currentColor",
                                              d: "M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }